<template>
    <setting-block-layout
        :loading="loading"
        @save="onSave"
        @cancel="onCancel"
    >
        <template #body>
            <div class="profile-twoFa__form">
                <div>
                    <div
                        class="profile-twoFa__radio"
                        style="margin: auto"
                    >
                        <form-radio
                            text="Enable"
                            data="Enable"
                            name="Enable"
                            :is-checked="twoFa"
                            @input="onChange($event)"
                        />
                        <form-radio
                            text="Disable"
                            data="Disable"
                            name="Disable"
                            :is-checked="!twoFa"
                            @input="onChange($event)"
                        />
                    </div>
                </div>
                <div
                    v-if="twoFa"
                    class="profile-twoFa"
                >
                    <ul class="profile-twoFa__steps">
                        <li>
                            <div class="profile-twoFa__steps-counter">
                                <b>
                                    Step 1
                                </b>
                            </div>
                            <div class="profile-twoFa__steps-inner">
                                Open up an authentication app on your phone. If you don't already have one, we recommend
                                <a
                                    href="https://authy.com/download/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Authy by Twilio
                                </a>
                                or Google-Authenticator (
                                <a
                                    href="https://drive.google.com/uc?export=download&confirm=no_antivirus&id=1FFcePHpBCvQTA-ht5mJVynp5fVDxRBiJ"
                                    target="_blank"
                                >
                                    Windows
                                </a>,
                                <a
                                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                                    target="_blank"
                                >
                                    Android
                                </a>,
                                <a
                                    href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                                    target="_blank"
                                >
                                    iOS
                                </a>
                                ).
                            </div>
                        </li>
                        <li>
                            <div class="profile-twoFa__steps-counter">
                                <b>
                                    Step 2
                                </b>
                            </div>
                            <div class="profile-twoFa__steps-inner">
                                Using the app, scan the QR code on the right or manually enter this secret key:
                                <b>
                                    {{ secret }}
                                </b>
                            </div>
                        </li>
                        <li>
                            <div class="profile-twoFa__steps-counter">
                                <b>
                                    Step 3
                                </b>
                            </div>
                            <div class="profile-twoFa__steps-inner">
                                Enter the 6-digit code generated by your authentication app.
                                <div class="profile-twoFa__steps-inner-form">
                                    <form-input
                                        v-model="$v.code.$model"
                                        outlined
                                        :field.sync="code"
                                        legend="Code"
                                        :errors="validationMessage($v.code)"
                                        :is-valid="$v.code.$dirty && !$v.code.$anyError"
                                        @input.native="$v.code.$touch()"
                                        @blur.native="$v.code.$touch()"
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="profile-twoFa__qr">
                        <img
                            :src="`data:image/jpeg;base64,${png}`"
                            width="200"
                            height="200"
                            alt="qr"
                        >
                    </div>
                </div>
            </div>
        </template>
    </setting-block-layout>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import SettingBlockLayout from '@/components/account-settings/SettingBlockLayout';
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import {
    SET_TWOFA_STATUS
} from '@/store/modules/client/mutation-types';
import {
    Enroll, EnableTwoFa, DisableTwoFa
} from '@/services/2FA';
// Vuelidate
import { validationRules } from '@/validation/2FA/Rules'
import { formMessages } from '@/validation/2FA/Messages'

const {
    mapMutations: mapClientMutations
} = createNamespacedHelpers('client');
export default {
    components: {
        SettingBlockLayout
    },
    mixins: [
        validationMixin
    ],
    validations: {
        ...validationRules
    },
    metaInfo: {
        title: 'Two-Factor authentication settings'
    },
    data() {
        return {
            secret: null,
            png: null,
            twoFa: false,
            code: null,
            sympleValid: false,
            correctOTP: true,
            loading: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getter2FA'
        ])
    },
    watch: {
        code() {
            if (!this.correctOTP) {
                this.correctOTP = true
                this.$nextTick(() => {
                    this.$v.$reset()
                })
            }
        }
    },
    created() {
        this.twoFa = Boolean(this.getter2FA)
        if (this.twoFa) {
            this.enroll()
        }
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        onCancel() {
            this.$router.push({ name: 'profile' })
        },
        ...mapClientMutations({
            SET_TWOFA_STATUS
        }),
        async onSave() {
            if (!this.twoFa) {
                this.$router.push({ name: 'profile' })
                return
            }
            this.sympleValid = true
            this.$v.$touch()
            this.$nextTick(() => {
                this.sympleValid = false
            })
        },
        async enroll() {
            const { secret, png } = await Enroll()
            this.secret = secret
            this.png = png
        },
        async onChange(payload) {
            if (payload === 'Enable') {
                this.enroll()
            }
            if (payload === 'Disable') {
                await DisableTwoFa()
                this[SET_TWOFA_STATUS](0)
            }
            this.twoFa = payload === 'Enable'
        },
        async checkCode() {
            this.correctOTP = await EnableTwoFa(this.code)
            if (this.correctOTP) {
                this[SET_TWOFA_STATUS](1)
                this.$router.push({ name: 'profile' })
            }
        }
    }
}
</script>
