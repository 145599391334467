import {
    required,
    minLength,
    maxLength
} from 'vuelidate/lib/validators';

export const validationRules = {
    code: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
        async isValidCode() {
            if (!this.$v.code.required || !this.$v.code.minLength || !this.$v.code.maxLength) {
                return false
            }
            if (this.sympleValid) {
                this.loading = true
                await this.checkCode()
                this.loading = false
                return this.correctOTP
            }
            return this.correctOTP
        }
    }
}
